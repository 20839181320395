import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="members"
export default class extends Controller {
  connect() {
    // console.log("Hello, Stimulus!", this.element);
  }

  showSuccessPopup(msg) {
    const successPopup = document.getElementById("success-popup");
    const successPopupMsg = document.getElementById("successPopupMsg");
    successPopup.classList.add("show");
    successPopup.style.display = "block";
    successPopupMsg.innerText = msg;

    setTimeout(() => {
      successPopup.style.display = "none";
      successPopup.classList.remove("show");
      window.location.reload();
    }, 1500);
  }

  showErrorPopup(msg) {
    const errorPopup = document.getElementById("error-popup");
    const errorPopupMsg = document.getElementById("errorPopupMsg");

    errorPopup.classList.add("show");
    errorPopup.style.display = "block";
    errorPopupMsg.innerText = msg;
    setTimeout(() => {
      errorPopup.style.display = "none";
      errorPopup.classList.remove("show");
      window.location.reload();
    }, 1500);
  }
  activate(e) {
    const member_id = e.currentTarget.dataset.memberId;
    fetch(`/activate_member/${member_id}`, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.showSuccessPopup(data.message);
        } else {
          this.showErrorPopup(data.error);
        }
      });
  }
}
