import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="posts"
export default class extends Controller {
  static targets = ["like", "link"];
  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  copyLink(e) {
    let link = window.location.href;
    navigator.clipboard
      .writeText(link)
      .then(this.linkTarget.classList.remove("fa-link"))
      .then(this.linkTarget.classList.add("fa-check"));
  }

  toggleLike() {
    let postId = this.likeTarget.dataset.post;
    fetch(`/likes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ post_id: postId }),
    })
      .then((response) => {
        if (response.ok) {
        }
      })
      .then((data) => {});
    if (this.likeTarget.classList.contains("is-active")) {
      this.likesCountTarget.innerText =
        parseInt(this.likesCountTarget.innerText) - 1;
    } else {
      this.likesCountTarget.innerText =
        parseInt(this.likesCountTarget.innerText) + 1;
    }
    this.likeTarget.classList.toggle("is-active");
  }
}
