// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require social-share-button
//= require social-share-button/wechat // if you need use WeChat

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "channels";

import "bootstrap";
import { stickyNavbar } from "../channels/sticky_navbar";
// import rails_admin from "rails_admin";
Rails.start();
Turbolinks.start();

import "controllers/index";
